import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "capitalize text-lg font-bold" }
const _hoisted_3 = { class: "text-gray-400" }
const _hoisted_4 = {
  key: 0,
  class: "flex justify-center items-center"
}
const _hoisted_5 = { class: "flex pt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageLoading = _resolveComponent("PageLoading")!
  const _component_Stage = _resolveComponent("Stage")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.category.name), 1),
      (!_ctx.readonly)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            color: "ghost",
            size: "small",
            class: "rounded-lg",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove-category')))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('custom.uhb.worklist-configuration.remove')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.category.worklist_stages, (stage, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "mt-12"
      }, [
        (stage.id === _ctx.loadingStageId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_PageLoading)
            ]))
          : (_openBlock(), _createBlock(_component_Stage, {
              key: 1,
              stage: stage,
              readonly: _ctx.readonly,
              class: "max-w-4xl mx-auto bg-white border z-50 shadow rounded-lg",
              onRemoveStage: ($event: any) => (_ctx.$emit('remove-stage', { stageId: stage.id })),
              onUpdateStageName: ($event: any) => (
          _ctx.$emit('update-stage-name', {
            categoryId: _ctx.category.id,
            stageId: stage.id,
            stageName: stage.name
          })
        ),
              onUpdateStageAction: ($event: any) => (
          _ctx.$emit('update-stage-action', {
            categoryId: _ctx.category.id,
            stageId: stage.id,
            stageName: stage.action
          })
        ),
              onUpdateStageFhirType: ($event: any) => (
          _ctx.$emit('update-stage-fhir-type', {
            categoryId: _ctx.category.id,
            stageId: stage.id,
            stageName: stage.fhir_type
          })
        )
            }, null, 8, ["stage", "readonly", "onRemoveStage", "onUpdateStageName", "onUpdateStageAction", "onUpdateStageFhirType"]))
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_BaseButton, {
        class: "w-full border border-gray-300 bg-white text-gray-700 shadow",
        color: "muted",
        size: "large",
        "left-icon": "add",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('add-stage')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.worklist-configuration.add-stage')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}