
import { Vue, Options } from 'vue-class-component';
import Category from '@/views/worklist-configuration/components/Category.vue';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import PageLoading from '@/lib/components/Loading/PageLoading.vue';
import { Category as CategoryModel } from '@/models/worklist.model';

@Options({
  components: { PageLoading, BaseButton, Category },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    isAddCategoryLoading: {
      type: Boolean,
      default: false
    },
    loadingCategoryId: {
      type: String,
      default: ''
    },
    loadingStageId: {
      type: String,
      default: ''
    },
    categories: {
      type: Array,
      default: () => []
    }
  }
})
export default class CategoriesAndStages extends Vue {
  loading!: boolean;
  loadingCategoryId!: string;
  loadingStageId!: string;
  isAddCategoryLoading!: boolean;

  categories!: Array<CategoryModel>;
}
