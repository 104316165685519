import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoriesAndStages = _resolveComponent("CategoriesAndStages")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_CategoriesAndStages, {
        loading: _ctx.isLoadingWorklist,
        "is-add-category-loading": _ctx.isAddCategoryLoading,
        categories: _ctx.worklist.worklist_categories,
        "loading-category-id": _ctx.loadingCategoryId,
        "loading-stage-id": _ctx.loadingStageId,
        onAddStage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addStage($event))),
        onAddCategory: _ctx.addCategory,
        onRemoveCategory: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removeCategory($event))),
        onRemoveStage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeStage($event)))
      }, null, 8, ["loading", "is-add-category-loading", "categories", "loading-category-id", "loading-stage-id", "onAddCategory"])
    ]),
    _: 1
  }))
}