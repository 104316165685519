
import { Vue, Options } from 'vue-class-component';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import BaseTextInput from '@/lib/components/Input/BaseTextInput.vue';
import BaseSelect from '@/lib/components/Select/BaseSelect.vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { IOption } from '@/lib';
import { Stage as StageModel } from '@/models/worklist.model';
import Status from '@/views/worklist-configuration/components/Status.vue';

@Options({
  components: { Status, BaseIcon, BaseSelect, BaseTextInput, BaseButton },
  props: {
    stage: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  }
})
export default class Stage extends Vue {
  stage!: StageModel;

  get statusTitle() {
    return [
      {
        label: this.$t('platform.common.status'),
        class: 'col-span-2'
      },
      {
        label: this.$t('custom.uhb.worklist-configuration.fhir-mapping'),
        class: 'col-span-1'
      }
    ];
  }

  get actionValue(): string {
    // TODO: Fix this once we confirm the action type api.
    return this.actionTypeOptions.length ? this.actionTypeOptions[0].value : '';
  }

  get fhirTypeValue(): string {
    // TODO: Fix this once we confirm the fhir type api.
    return this.stage.fhir_type ? this.stage.fhir_type : '';
  }

  get actionTypeOptions(): Array<IOption> {
    // TODO: Fix this once we confirm the action type api.
    if (this.stage.worklist_actions.length) {
      return [
        {
          label: this.stage.worklist_actions[0].worklist_action_type.name,
          value: this.stage.worklist_actions[0].worklist_action_type.id
        }
      ];
    }
    return [];
  }

  get fhirTypeOptions(): Array<IOption> {
    // TODO: Fix this once we confirm the fhir type api.
    if (this.stage.fhir_type) {
      return [
        {
          label: this.stage.fhir_type,
          value: this.stage.fhir_type
        }
      ];
    }
    return [];
  }
}
