
import { Vue, Options } from 'vue-class-component';
import CategoriesAndStages from '@/views/worklist-configuration/components/CategoriesAndStages.vue';
import DefaultLayout from '@/lib/layouts/DefaultLayout.vue';
import { Category, Stage, Worklist } from '@/models/worklist.model';
import WorklistService from '@/services/api/worklist.service';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: { DefaultLayout, CategoriesAndStages }
})
export default class ConfigurationPage extends Vue {
  worklistService!: WorklistService;
  worklistId = '';

  isLoadingWorklist = false;
  isAddCategoryLoading = false;
  loadingCategoryId = '';
  loadingStageId = '';

  notificationStore = useNotificationStore();

  // TODO: Use real data once backend api is ready
  worklist: Worklist = {
    id: '4ea9b8df-9b88-48b5-8378-74d07732ee95',
    name: 'worklist 1',
    worklist_categories: []
  };

  // TODO: Use real data once backend api is ready
  mockStage: Stage = {
    id: `id-stage-${Date.now()}`,
    name: `stage name for category ${Date.now()}`,
    fhir_type: 'Encounter',
    worklist_actions: [
      {
        id: `id-action-${Date.now()}`,
        name: null,
        actionable_type: null,
        actionable_id: 'cb0977a4-531e-4b5d-b8d1-1a6491f7c91d',
        worklist_action_type: {
          id: 'b32448cf-2efb-435f-ab53-544abd87f88a',
          name: 'Form',
          description: 'The Demo form'
        }
      }
    ],
    statuses: []
  };

  // TODO: Use real data once backend api is ready
  mockCategory: Category = {
    id: `id-category-${Date.now()}`,
    name: 'category name 1',
    worklist_stages: [
      {
        id: 'a73dd84d-99dd-470e-96b3-a42c555f1e3b',
        name: 'stage name for category 1',
        fhir_type: 'Encounter',
        worklist_actions: [
          {
            id: 'd4012165-0efe-4899-a77e-e7c0bbd03e0e',
            name: null,
            actionable_type: null,
            actionable_id: 'cb0977a4-531e-4b5d-b8d1-1a6491f7c91d',
            worklist_action_type: {
              id: 'b32448cf-2efb-435f-ab53-544abd87f88a',
              name: 'Form',
              description: 'The Demo form'
            }
          }
        ],
        statuses: [
          {
            id: '9bc28c9d-01c4-4d29-a164-433d925e0883',
            name: 'Waitlist',
            fhirMapping: {
              id: '899de6de-3f5e-452f-ae61-0828b9c01c04',
              name: 'Planned'
            }
          }
        ]
      },
      {
        id: '6a4c58d8-65a9-4540-8394-e2894d5b5ae3',
        name: 'stage name for category 2',
        fhir_type: 'Appointment',
        worklist_actions: [
          {
            id: 'd3b10a7e-da7b-4d3c-b3ab-73d275b966f6',
            name: null,
            actionable_type: null,
            actionable_id: 'cb0977a4-531e-4b5d-b8d1-1a6491f7c91d',
            worklist_action_type: {
              id: 'b32448cf-2efb-435f-ab53-544abd87f88a',
              name: 'Form',
              description: 'The Demo form'
            }
          }
        ],
        statuses: [
          {
            id: '9bc28c9d-01c4-4d29-a164-433d925e0883',
            name: 'Waitlist',
            fhirMapping: {
              id: '899de6de-3f5e-452f-ae61-0828b9c01c04',
              name: 'Planned'
            }
          },
          {
            id: 'a009493f-bd74-4dbd-a068-53e075ea17c7',
            name: 'On Hold',
            fhirMapping: {
              id: '9c5aa88c-0a09-4814-a602-5b93cd02d4b0',
              name: 'In Progress'
            }
          }
        ]
      }
    ]
  };

  async created() {
    this.worklistId = this.$route.params.worklistId;
    this.worklistService = new WorklistService();

    await this.fetchWorklist(this.worklistId);
  }

  async fetchWorklist(worklistId: string) {
    try {
      this.isLoadingWorklist = true;
      // TODO: we can switch back to mock data if needed.
      this.worklist = await this.worklistService.fetch(worklistId);
      // await setTimeout(() => {
      //   this.worklist = {
      //     ...this.worklist,
      //     name: 'worklist 1',
      //     worklist_categories: [{ ...this.mockCategory }]
      //   };
      // });
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.worklist-configuration.fetch-worklist-error')
      });
    } finally {
      this.isLoadingWorklist = false;
    }
  }

  addStage(categoryId: string) {
    // TODO - replace all this with an api call and then refresh
    const now = Date.now();
    this.mockStage = {
      ...this.mockStage,
      id: `id-stage-${now}`,
      name: `stage name for category ${now}`
    };
    this.worklist.worklist_categories = this.worklist.worklist_categories.map((category: Category) => {
      if (categoryId === category.id) {
        category.worklist_stages.push(this.mockStage);
      }
      return category;
    });
  }

  addCategory() {
    // TODO replace with api call and then refresh
    this.isAddCategoryLoading = true;
    setTimeout(() => {
      this.worklist.worklist_categories = [
        ...this.worklist.worklist_categories,
        {
          worklist_stages: [],
          name: `Category ${this.worklist.worklist_categories.length + 1}`,
          id: `id-${this.worklist.worklist_categories.length + 1}-${Date.now()}`
        }
      ];
      this.isAddCategoryLoading = false;
    }, 500);
  }

  removeCategory(categoryId: string) {
    // TODO replace with api call and then refresh
    const loadingCategory = this.worklist.worklist_categories.find((category: Category) => categoryId === category.id);
    if (loadingCategory) {
      this.loadingCategoryId = loadingCategory.id ? loadingCategory.id : '';
    }

    setTimeout(async () => {
      this.worklist.worklist_categories = this.worklist.worklist_categories.filter(
        (category: Category) => categoryId !== category.id
      );

      await this.clearLoadingCategoryId();
    }, 1000);
  }

  removeStage(identifiers: { categoryId: string; stageId: string }) {
    // TODO - mock functionality - if id's are always present, just pass through id's instead of indices
    const { categoryId, stageId } = identifiers;
    this.loadingStageId = stageId;

    const loadingCategory = this.worklist.worklist_categories.find((category: Category) => category.id === categoryId);
    if (loadingCategory) {
      const loadingStage = loadingCategory.worklist_stages.find((stage: Stage) => stage.id === stageId);
      this.loadingStageId = loadingStage ? loadingStage.id : '';
    }

    setTimeout(() => {
      const categoryIndex = this.worklist.worklist_categories.findIndex(
        (category: Category) => category.id === categoryId
      );

      this.worklist.worklist_categories[categoryIndex].worklist_stages = this.worklist.worklist_categories[
        categoryIndex
      ].worklist_stages.filter((stage: Stage) => stageId !== stage.id);
      this.clearLoadingStageId();
    }, 1000);
  }

  clearLoadingCategoryId() {
    this.loadingCategoryId = '';
  }

  clearLoadingStageId() {
    this.loadingStageId = '';
  }
}
