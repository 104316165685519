import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "max-w-5xl mt-8 z-50 mx-auto rounded-lg bg-gray-800 h-20 flex items-center justify-start text-white p-8 text-2xl" }
const _hoisted_4 = {
  key: 0,
  class: "flex justify-center items-center"
}
const _hoisted_5 = { class: "max-w-4xl mx-auto flex justify-center pt-8 mb-8" }
const _hoisted_6 = {
  key: 0,
  class: "flex justify-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageLoading = _resolveComponent("PageLoading")!
  const _component_Category = _resolveComponent("Category")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_PageLoading)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('custom.uhb.worklist-configuration.categories-and-stages')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "mt-12"
            }, [
              (_ctx.loadingCategoryId === category.id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_PageLoading)
                  ]))
                : (_openBlock(), _createBlock(_component_Category, {
                    key: 1,
                    category: category,
                    "loading-stage-id": _ctx.loadingStageId,
                    class: "max-w-4xl mx-auto bg-white border rounded-lg p-6 z-50 shadow",
                    onAddStage: ($event: any) => (_ctx.$emit('add-stage', category.id)),
                    onRemoveCategory: ($event: any) => (_ctx.$emit('remove-category', category.id)),
                    onRemoveStage: ($event: any) => (
            _ctx.$emit('remove-stage', {
              categoryId: category.id,
              stageId: $event.stageId
            })
          )
                  }, null, 8, ["category", "loading-stage-id", "onAddStage", "onRemoveCategory", "onRemoveStage"]))
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.isAddCategoryLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_PageLoading)
                ]))
              : (_openBlock(), _createBlock(_component_BaseButton, {
                  key: 1,
                  class: "w-full border-dashed border-2 border-primary-700 bg-primary-100 text-primary-700 hover:text-white",
                  color: "primary",
                  size: "large",
                  "left-icon": "add",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add-category')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.worklist-configuration.add-category')), 1)
                  ]),
                  _: 1
                }))
          ])
        ]))
  ]))
}