
import { Vue, Options } from 'vue-class-component';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import Stage from '@/views/worklist-configuration/components/Stage.vue';
import PageLoading from '@/lib/components/Loading/PageLoading.vue';

@Options({
  components: { PageLoading, Stage, BaseButton },
  props: {
    loadingStageId: {
      type: String,
      default: ''
    },
    category: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  }
})
export default class Category extends Vue {}
